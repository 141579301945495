input {
    margin: 0;
}

select {
    margin: 0;
    font-size: 16px;
    border-radius: 10px;
    padding: 5px 10px;
}


